import { Calendar, CalendarChangeEvent, CalendarSelectEvent, CalendarVisibleChangeEvent } from 'primereact/calendar'
import React, { useEffect, useState } from 'react'
import { mai_migration_timeframe } from '../../../../../../assets/images';
import useHttp from '../../../../../../hooks/use-http';
import { getSegmentationTimeframeResponse, runSegmentationResponse } from '../../../../../../models/types';
import { useParams } from 'react-router-dom';
import ApiService from '../../../../../../services/api.service';
import ChartComponent from '../../../../../../shared/components/chart/ChartComponent';
import Loader from '../../../../../../shared/components/loader/Loader';

const SegmentationMigration = () => {
  const params = useParams();
  const [date1, setDate1] = useState<Date | null>(null);
  const [date2, setDate2] = useState<Date | null>(null);
  const [date3, setDate3] = useState<Date | null>(null);
  const { sendRequest: getSegmentTimeframe } = useHttp();
  const { sendRequest: runSegmentMigration, loading: runSegmentMigrationLoader } = useHttp();
  const [timeframe, setTimeframe] = useState<{ mindate?: Date, maxdate?: Date }>({ mindate: undefined, maxdate: undefined });
  const [chartConfig, setChartConfig] = useState<any>({
    type: "sankey",
    width: "100%",
    height: "53%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption: "",
        legendPosition: "bottom",
        linkcolor: "blend",
        showLegend: "0",
        showValue: "1",
        theme: "fusion",
        nodeLabelFontColor: "#000000",
        nodeLabelFontBold: '1',
      },
      nodes: [],
      links: [],
    },
  });


  const padTo2Digits = (date: number) => {
    return date.toString().padStart(2, '0');
  }

  const formatDate = (date?: Date | null) => {
    return date !== undefined && date !== null ? [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') : '';
  }

  const convertToDate = (dateString?: string) => {
    //  Convert a "yyyy-mm-dd" string into a Date object
    if (dateString !== undefined && dateString !== '') {
      let d = dateString.split("-");
      let dat = new Date(d[0] + '/' + d[1] + '/' + d[2]);
      return dat;
    } else {
      return undefined;
    }
  }
  const runClickHandler = () => {
    const resHandler = (res: runSegmentationResponse) => {
      const uniqueNodes: { [key in string]: { label: string, key: string } } = {};
      const links: { from: string, to: string, value: number }[] = [];
      res.data.filter(item => formatDate(date3) === '' ? !(item.from_timeframe.toString() === 'timeframe_3' || item.to_timeframe.toString() === 'timeframe_3') : true).forEach(item => {
        if (item.from.toString() !== "0" && item.to.toString() !== "0") {
          uniqueNodes[`${item.from}-${item.from_timeframe}`] = { key: `${item.from} T${item.from_timeframe.split('_')[1]}`, label: item.from };
          uniqueNodes[`${item.to}-${item.to_timeframe}`] = { key: `${item.to} T${item.to_timeframe.split('_')[1]}`, label: item.to };
          links.push({
            from: `${item.from} T${item.from_timeframe.split('_')[1]}`,
            to: `${item.to} T${item.to_timeframe.split('_')[1]}`,
            value: item.value
          });
        }
      })
      setChartConfig((prevChartConfig: any) => ({
        ...prevChartConfig,
        dataSource: {
          ...prevChartConfig.dataSource,
          nodes: Object.entries(uniqueNodes).map(([key, value]) => ({ label: value.key, showValue: "1", labelPosition: "start" })),
          links: links,
        },
      }));
    };
    if (params?.sid) {
      runSegmentMigration(
        ApiService.runSegmentationMigration(params?.sid, { timeframe_1: formatDate(date1), timeframe_2: formatDate(date2), timeframe_3: formatDate(date3) }),
        resHandler
      );
    }
  }
  useEffect(() => {
    const resHandler = (res: getSegmentationTimeframeResponse) => {
      const minDate = convertToDate(res.min_date)!;
      setTimeframe({
        mindate: new Date(minDate.setMonth(minDate.getMonth() + 1)),
        maxdate: convertToDate(res.max_date),
      });
      setDate1(new Date(minDate.setMonth(minDate.getMonth() + 1)));
      setDate2(new Date(minDate.setMonth(minDate.getMonth() + 1)));
    };
    if (params?.sid) {
      getSegmentTimeframe(
        ApiService.getSegmentationDefineTimeFrame(params?.sid),
        resHandler
      );
    }
  }, [getSegmentTimeframe, params?.sid]);

  return (
    <>
      <div className="audiencecreate__form-wrapper segmentmigration segmentdetails bordertop-header box-shadow p-3 whitebox-height-with-tabs">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h1 className="segmentmigration__migrationtitle">Choose migration timeframe</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <label htmlFor="timeframe1">Timeframe 1</label>
            <div className="defineCalendar d-block">
              <Calendar id="timeframe1" panelClassName='range-calendar-pannel' inputStyle={{ width: '50%' }} minDate={timeframe.mindate} maxDate={timeframe.maxdate} value={date1} onHide={() => { console.log("calendar Hide"); }} onBlur={(e: any) => { console.log("calendar blur", e.value); }} onSelect={(e: CalendarSelectEvent) => setDate1(e.value as Date)} placeholder="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <label htmlFor="timeframe2">Timeframe 2</label>
            <div className="defineCalendar d-block">
              <Calendar id="timeframe2" panelClassName='range-calendar-pannel' inputStyle={{ width: '50%' }} minDate={date1 ?? timeframe.mindate} maxDate={timeframe.maxdate} value={date2} onChange={(e: CalendarChangeEvent) => setDate2(e.value as Date)} placeholder="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <label htmlFor="timeframe3">Timeframe 3 <span>Optional</span></label>
            <div className="defineCalendar d-block">
              <Calendar id="timeframe3" panelClassName='range-calendar-pannel' inputStyle={{ width: '50%' }} minDate={date2 ?? timeframe.mindate} maxDate={timeframe.maxdate} value={date3} onChange={(e: CalendarChangeEvent) => setDate3(e.value as Date)} placeholder="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <button type="submit" className="btn btn-primary mai-next-button mt-4" onClick={runClickHandler} disabled={runSegmentMigrationLoader || !date1 || !date2}>Run</button>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-5 text-center">
            {runSegmentMigrationLoader ? <Loader style={{minHeight: '400px'}}></Loader> : 
              <ChartComponent chartConfigs={chartConfig} />
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SegmentationMigration