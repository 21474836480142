import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import './Login.scss';
import { 
    mai_logo_dark, 
    mai_build_your_audience,
    mai_power_your_data, 
    mai_deliver_highly,
    mai_evs_short_logo, 
    mai_logo_white
} from './../../assets/images/index';
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { TenantProviders, Url } from "../../models/constants";
import { AuthProvider, TenantType } from "../../models/types";
import AuthenticationService from "../../services/authentication.service";
import useHttp from "../../hooks/use-http";
import { useLanguage } from "../../hooks/LanguageHook";
import AuthenticationHook from "../../hooks/AuthenticationHook";
import Loader from "../../shared/components/loader/Loader";
const LoginComponent = () => {
    const { locale } = useLanguage();
    const navigate = useNavigate();
    const auth = AuthenticationHook.useAuth();
    const next = `/${Url.MAIN}/${Url.HOME}`;
    const params: TenantType | any = useParams();
    const location = useLocation();
    const UrlSearchParams: any = useMemo(() => new URLSearchParams(location?.search), [location?.search]);
    const { sendRequest: getDefaultTenant, loading: defaultTenantLoader } = useHttp();
    const { sendRequest: getAuthProvider, loading: authProviderLoader } = useHttp();
    const { sendRequest: doNormalLogin, loading: normalLoginLoader } = useHttp();
    const { sendRequest: doAdfsLogin } = useHttp();
    const [selectedTenant, setSelectedTenant] = useState<string>('');
    const [localLoginEnabled, setLocalLoginEnabled] = useState<boolean>(false);
    const [ssoLoginEnabled, setssoLoginEnabled] = useState<boolean>(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const tenantnameRef = useRef<HTMLInputElement>(null);
    const [inValid, setInvalid] = useState(false);
    const fetchAuthProvider = useCallback((tenant: string) => {
        const data: TenantType | any = { tenant };
        const resHandler = (res: AuthProvider) => {
            if (res.provider === TenantProviders.LOCAL) {
                setLocalLoginEnabled(true);
            } else if (res.provider === TenantProviders.ADFS) {
                const adfsResHandler = (adfsRes: any) => {
                    // adfs login response
                }
                doAdfsLogin(AuthenticationService.adfsLogin(data), adfsResHandler)

            } else if (res.provider === TenantProviders.AZURE) {
                console.log('in azure block');
                setssoLoginEnabled(true);
            }
        }
        getAuthProvider(AuthenticationService.getAuthProvider(data), resHandler)
    }, [doAdfsLogin, getAuthProvider]);
    const fetchDefaultTenant = useCallback(() => {
        const resHandler = (res: any) => {
            console.log(res);
            setSelectedTenant(res?.tenant); //res.tenant
            fetchAuthProvider(res?.tenant); //evalueserve-local
        }
        getDefaultTenant(AuthenticationService.getDefaultTenant(), resHandler);
    }, [fetchAuthProvider, getDefaultTenant]);

    useEffect(() => {
        console.log(params);
        if (location.search.trim().length > 0) {
            //After Successful SSO Login Redirection logic lies here
            console.log(location, UrlSearchParams.get('access_token'));
            auth.signin({
                accessToken: UrlSearchParams.get('access_token'),
                uid: UrlSearchParams.get('id')
            }, () => navigate(next));
        } else {
            if (Object.keys(params).indexOf(Url.TENANT) > -1) {
                setSelectedTenant(params?.tenant)
                fetchAuthProvider(params?.tenant)
            } else {
                // Get default Tenant
                fetchDefaultTenant();
            }
        }
        return () => {
            // clean up function
        }
    }, [UrlSearchParams, auth, fetchAuthProvider, fetchDefaultTenant, location, navigate, next, params]);
    const normalLoginHandler = (event: React.FormEvent) => {
        event.preventDefault();
        const username = usernameRef.current!.value;
        const password = passwordRef.current!.value;
        const data = {
            login: username,
            password,
            tenant: selectedTenant
        };
        if (username.trim().length === 0 || password.trim().length === 0) {
            setInvalid(true);
        }
        const resHandler = (res: any) => {
            console.log(res);
            auth.signin({
                accessToken: res.accessToken,
                uid: res.login
            }, () => {
                navigate(next);
            });
        }
        doNormalLogin(AuthenticationService.normalLogin(data), resHandler);
    }
    const ssoLoginHandler = () => {
        const data = { tenant: selectedTenant };
        window.location.href = AuthenticationService.azureLogin(data);
    }
    const tenantSubmit = (event: any) => {
        event.preventDefault();
        if(tenantnameRef?.current?.value) {
            window.location.href =  `/${tenantnameRef?.current?.value}`
        }
    }
    return (
        <Fragment>
            <section className="mai-login">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 p-0">
                            <div className="login__leftsection loginBg">
                                <div className="login__leftsection-inner">
                                    <div className="login__leftsection-heading">
                                        <h2 className="login__leftheading-title">
                                            AI-Powered Self-Serve Platform for Optimizing CX and Maximizing Customer Lifetime Value
                                        </h2>
                                    </div>
                                    <div className="login__subheading">
                                        <h3 className="login__subheading-title">{locale('Login.whats_new')}</h3>
                                    </div>
                                    <div className="login__whatsnew-box row">
                                        <div className="login__whatsnew-inner col-lg-4 col-md-4 col-sm-12 mb-2">
                                            <figure>
                                                <img src={mai_build_your_audience} className="img-fluid login__whatsnew-img" alt="build-your-audience" />
                                                <div className="login__whatsnew-desc">
                                                    <figcaption className="login__img-desc">{locale('Login.image1_text')}</figcaption>
                                                    <a href="https://www.evalueserve.com/technology-partners/google-cloud/intellilens-marketing-intelligence/" className="login__knowmore" rel="noopener noreferrer">{locale('Login.know_more')} <span className="login__right-arrow"></span></a>
                                                </div>
                                            </figure>
                                        </div>
                                        <div className="login__whatsnew-inner col-lg-4 col-md-4 col-sm-12 mb-2">
                                            <figure>
                                                <img src={mai_power_your_data} className="img-fluid login__whatsnew-img" alt="power-your-data" />
                                                <div className="login__whatsnew-desc">
                                                    <figcaption className="login__img-desc">{locale('Login.image2_text')}</figcaption>
                                                    <a href="https://www.evalueserve.com/technology-partners/google-cloud/intellilens-marketing-intelligence/" className="login__knowmore">{locale('Login.know_more')} <span className="login__right-arrow"></span></a>
                                                </div>
                                            </figure>
                                        </div>
                                        <div className="login__whatsnew-inner col-lg-4 col-md-4 col-sm-12 mb-2">
                                            <figure>
                                                <img src={mai_deliver_highly} className="img-fluid login__whatsnew-img" alt="deliver-highly" />
                                                <div className="login__whatsnew-desc">
                                                    <figcaption className="login__img-desc">{locale('Login.image3_text')}</figcaption>
                                                    <a href="https://www.evalueserve.com/technology-partners/google-cloud/intellilens-marketing-intelligence/" className="login__knowmore">{locale('Login.know_more')} <span className="login__right-arrow"></span></a>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                            <div className="login__box">
                                <div className="login__inner">
                                    <div className="login__logo">
                                        <img src={mai_logo_white} className="img-fluid" alt="Logo" />
                                    </div>
                                    <div className="login__title">
                                        <h1>{locale('Login.login')}</h1>                                        
                                    </div>
                                    {!selectedTenant && !defaultTenantLoader ? 
                                        <>
                                            <div className="login__invalid">Authentication Failed. Please enter tenant manually.</div> 
                                            <form onSubmit={tenantSubmit}>
                                                <div className="mb-3 login__form-group">
                                                    <label htmlFor="username" className="form-label login__label">{locale('Login.tenant')}</label>        
                                                    <input type="text" name="tenant" ref={tenantnameRef} className="form-control tenant__input" id="tenant" placeholder={locale('Login.tenant')} required />
                                                </div>
                                                <div className="login__button-group">
                                                    <button type="submit" className="btn btn-primary">{locale('Login.tenant_button')}</button>
                                                </div>
                                            </form>
                                        </>
                                        : ''
                                    } 
                                    {authProviderLoader ?
                                        <Loader></Loader>
                                        :
                                            <>
                                            {localLoginEnabled &&
                                                <Loader isLoading={normalLoginLoader}>
                                                    <form name="login" onSubmit={normalLoginHandler}>
                                                        <div className="mb-3 login__form-group">
                                                            <label htmlFor="username" className="form-label login__label">{locale('Login.email')}</label>
                                                            <input type="text" className="form-control login__input" ref={usernameRef} id="username" placeholder={locale('Login.email_placeholder')} required />
                                                        </div>
                                                        <div className="mb-3 login__form-group">
                                                            <label htmlFor="password" className="form-label login__label">{locale('Login.password')}</label>
                                                            <input type="password" className="form-control login__input" ref={passwordRef} id="password" placeholder={locale('Login.password_placeholder')} required />
                                                        </div>
                                                        <div className="mb-3 login__form-group">
                                                            <NavLink to="" className="login__forgotpass">{locale('Login.forgot_password')}</NavLink>
                                                        </div>
                                                        <div className="login__button-group">
                                                            <button type="submit" className="btn btn-primary">{locale('Login.login_button')}</button>
                                                            {/* <Button type="submit" loading={normalLoginLoader}>{locale('Login.login_button')}</Button> */}
                                                        </div> 
                                                    </form>
                                                </Loader>
                                            }
                                            {ssoLoginEnabled &&
                                                <div className="login__button-group">
                                                    <button type="button" className="btn btn-primary" onClick={ssoLoginHandler}>{locale('Login.evs_login_button')}</button>
                                                </div>
                                            }
                                        </>}
                                    <div className="login__agreement">
                                        <p className="login__agreement-para">{locale('Login.login_agreement')}</p>
                                        <NavLink to="/" className="login__agreement--terms me-1">{locale('Login.terms_ofuse')}</NavLink>
                                        <NavLink to="/" className="login__agreement--terms">{locale('Login.privacy_policy')}</NavLink>
                                    </div>
                                    {/* <div className="login__copyright">
                                        <p className="login__copyright-text">
                                            {locale('Login.copyright', { variable: new Date().getFullYear() })}
                                        </p>
                                    </div> */}
                                </div>

                                <img src={mai_evs_short_logo} alt="evs short logo" className="img-fluid login__box-evsshort-logo" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Fragment>
    )
}
export default React.memo(LoginComponent);