export const authApiUrl = (window['env'] && window['env']['AUTHAPIURL']) ? window['env']['AUTHAPIURL'] : 'https://demo-magnifai-auth.apps-evalueserve.com';
export const apiUrl = (window['env'] && window['env']['APIURL']) ? window['env']['APIURL'] : 'https://demo-magnifai-api.apps-evalueserve.com';
export const campaignUrl = (window['env'] && window['env']['CAMPAIGNURL']) ? window['env']['CAMPAIGNURL'] : 'https://demo-campaignlens-api.apps-evalueserve.com';
export const ssoUrl = (window['env'] && window['env']['SSOURL']) ? window['env']['SSOURL'] : 'https://apps-evalueserve.com';
export const environment = {
    authApiUrl,
    apiUrl,
    campaignUrl,
    ssoUrl
}
