import React, { useCallback, useEffect, useState } from 'react'
import { Columns } from '../../../../../../models/types';
import UtilService from '../../../../../../services/util.service';
import DataTable from '../../../../../../shared/components/datatable/DataTable'
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../shared/components/loader/Loader';
import { Tooltip } from 'primereact/tooltip';

const TopCountriesComponent = (props: any) => {
    const [tableData, setTableData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [labelCount, setLabelCount] = useState<number>(5);
    let params: any = useParams();
    const { sendRequest: getPersonaTopCountries, loading: personaTopCountriesLoader } = useHttp();
    const PersonaTopCountries = useCallback(() => {
        const selectedSegmentIds = [...props.selectedPersonas.map((item: any) => item.id)];
        const resHandler = (res: any) => {
            const tableData: any = [];
            setLabelCount(res?.response?.reduce((max: number, item: any) => Math.max(max, item.details.length), 0) ?? 5);
            selectedSegmentIds.forEach((segmentId: any, segmentIndex: number) => {
                const record: any = {};
                const filteredData = res.response.find((item: any) => item.segments.toString() === segmentId.toString());
                record.segment = props.selectedPersonas.find((sitem: any) => sitem.id === segmentId).name;
                filteredData?.details?.forEach((item: any, index: number) => {
                    record[`country${index + 1}`] = item.country ?? '';
                    record[`customer${index + 1}`] = item.customers ?? '';
                    record[`total_customers`] = filteredData.total_customers ?? '';
                    record['index'] = segmentIndex;
                });
                tableData.push(record);
            });
            setLoading(false);
            setTableData(tableData);
        }
        if (props.start_date && props.end_date) {
            getPersonaTopCountries(ApiService.getSegmentationPersonaTopCountries(params.sid, selectedSegmentIds, props.start_date, props.end_date, props.region), resHandler)
        }
    }, [getPersonaTopCountries, params.sid, props.end_date, props.region, props.selectedPersonas, props.start_date]);
    useEffect(() => {
        if (props.selectedPersonas.length > 0) {
            PersonaTopCountries();
        }
    }, [PersonaTopCountries, props.selectedPersonas, params.sid, props.start_date, props.end_date, props.region]);

    const customersBodyTemplate1 = (rowData: any) => {
        return <>
            {`${rowData.country1 ?? ''} `}
            <span>{UtilService.percentageCalculator(rowData.customer1, rowData.total_customers) ? `${UtilService.percentageCalculator(rowData.customer1, rowData.total_customers).toFixed(0)}%` : ''}</span>
        </>
    }
    const customersBodyTemplate2 = (rowData: any) => {
        return <>
            {`${rowData.country2 ?? ''} `}
            <span>{UtilService.percentageCalculator(rowData.customer2, rowData.total_customers) ? `${UtilService.percentageCalculator(rowData.customer2, rowData.total_customers).toFixed(0)}%` : ''}</span>
        </>
    }
    const customersBodyTemplate3 = (rowData: any) => {
        return <>
            {`${rowData.country3 ?? ''} `}
            <span>{UtilService.percentageCalculator(rowData.customer3, rowData.total_customers) ? `${UtilService.percentageCalculator(rowData.customer3, rowData.total_customers).toFixed(0)}%` : ''}</span>
        </>
    }
    const customersBodyTemplate4 = (rowData: any) => {
        return <>
            {`${rowData.country4 ?? ''} `}
            <span>{UtilService.percentageCalculator(rowData.customer4, rowData.total_customers) ? `${UtilService.percentageCalculator(rowData.customer4, rowData.total_customers).toFixed(0)}%` : ''}</span>
        </>
    }
    const customersBodyTemplate5 = (rowData: any) => {
        return <>
            {`${rowData.country5 ?? ''} `}
            <span>{UtilService.percentageCalculator(rowData.customer5, rowData.total_customers) ? `${UtilService.percentageCalculator(rowData.customer5, rowData.total_customers).toFixed(0)}%` : ''}</span>
        </>
    }

    const segmentBodyTemplete = (rowData: any) => {
        return <>
            <Tooltip className="list-tooltip-wrap" target={`.listtooltip${rowData.index}`} position="bottom">
                {rowData.segment}
            </Tooltip>
            <div className={`listtooltip${rowData.index}`}>
                <div className='countriesNameIcon'>
                    <i className="bi bi-person-fill user" style={{ color: UtilService.returnColorSchema(true)[rowData.index] }}></i>
                </div>
            </div>
        </>

    }
    const columns: Columns[] = [
        {
            field: "segment",
            body: segmentBodyTemplete,
            header: "",
            sort: false
        },
        {
            field: "country1",
            header: "",
            body: customersBodyTemplate1,
            sort: true,
        },
        {
            field: "country2",
            header: "",
            body: customersBodyTemplate2,
            sort: true,
        },
        {
            field: "country3",
            header: "",
            body: customersBodyTemplate3,
            sort: true,
        },
        {
            field: "country4",
            header: "",
            body: customersBodyTemplate4,
            sort: true,
        },
        {
            field: "country5",
            header: "",
            body: customersBodyTemplate5,
            sort: true,
        }
    ];
    return (
        <>
            <div className="insights__innerwrapper chartsminheight">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className='inner__heading mb-3'><span> Top {labelCount} Countries </span></h2>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                        <div className="dropdown industries-dropdown">
                            <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="pi pi-ellipsis-v dots3"></span>
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className='top5countries'>
                            {(personaTopCountriesLoader || loading) ? <Loader style={{ minHeight: "319px" }} /> :
                                <DataTable data={tableData} columns={columns}></DataTable>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(TopCountriesComponent);