import React, { Fragment, useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  AudienceDeatil,
  AudienceListResponse,
  CLVGeneralDetail,
  SegmentationDetail,
  SegmentationListResponse,
  SegmentationSummaryResponse,
  SubSegmentDetails,
  getCLVTimeframeResponse,
} from "../../../../../../models/types";
import "./ClvGeneralDetails.scss";
import useHttp from "../../../../../../hooks/use-http";
import ApiService from "../../../../../../services/api.service";
import AuthenticationService from "../../../../../../services/authentication.service";
import { Url } from "../../../../../../models/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  mai_plus_solid_blue,
} from "../../../../../../assets/images/index";
import Loader from "../../../../../../shared/components/loader/Loader";
import { StoreActions } from "../../../../../../store/actions";
import { AutoComplete } from "primereact/autocomplete";
import { StoreState } from "../../../../../../store/store";
import { Calendar } from "primereact/calendar";

const ClvGeneralDetailsComponents = () => {
  const params = useParams();
  const { clvid } = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [clv, setClv] = useState<CLVGeneralDetail>({
    name: "",
    description: "",
    is_private: false,
    can_be_accessed_by: [],
    source: 'audience',
    source_id: [],
    clv_base: false,
    clv_base_id: "",
    clv_segments: [],
    clv_predict: 3,
    min_date: "",
    max_date: "",
  });
  const { sendRequest: createCLV, loading: createClvLoader } = useHttp();
  const { sendRequest: updateCLV, loading: updateClvLoader } = useHttp();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const { sendRequest: getUsers } = useHttp();
  const { sendRequest: getAudiences } = useHttp();
  const [selectedAudience, setSelectedAudience] = useState<any>();
  const currentUserDetails = useSelector<StoreState, any>(
    (state) => state.currentUser
  );
  const [filteredAudienceList, setFilteredAudienceList] = useState<
    AudienceDeatil[]
  >([]);
  const [selectedSegments, setSelectedSegments] = useState<any>();
  const [filteredSegmentList, setFilteredSegmentList] = useState<
    SegmentationDetail[]
  >([]);
  const [selectedSubSegments, setSelectedSubSegments] = useState<any>([]);
  const [filteredSubSegmentList, setFilteredSubSegmentList] = useState<
    SubSegmentDetails[]
  >([]);
  const [allSubSegmentList, setAllSubSegmentList] = useState<
    SubSegmentDetails[]
  >([]);
  const [enableNext, setEnableNext] = useState(false);
  const { sendRequest: getAudience } = useHttp();
  const { sendRequest: getSegmentations } = useHttp();
  const { sendRequest: getSegmentation } = useHttp();
  const { sendRequest: getSubSegments } = useHttp();
  const { sendRequest: getClvGeneralDetails } = useHttp();
  const { sendRequest: runClvModel, loading: runClvModelLoader } = useHttp();
  const [fromdate, setFromDate] = useState<Date | undefined>();
  const [todate, setTodate] = useState<Date | undefined>();
  const [timeframe, setTimeframe] = useState<{
    mindate?: Date;
    maxdate?: Date;
  }>({ mindate: undefined, maxdate: undefined });
  const { sendRequest: getClvTimeframe } = useHttp();
  const header = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Create CLV")
      headerTitleChangeHandler("Create CLV",'','');
  })
  const fetchTimeframe = (event: any) => {
    if (event.value.id) {
      const resHandler = (res: getCLVTimeframeResponse) => {
        if (res.min_date && res.max_date) {
          setTimeframe({
            mindate: convertToDate(res.min_date),
            maxdate: convertToDate(res.max_date),
          });
          setFromDate(convertToDate(res.min_date));
          setTodate(convertToDate(res.max_date));
          setClv((oldclv: CLVGeneralDetail) => ({
            ...oldclv,
            min_date: res.min_date,
            max_date: res.max_date,
          }));
        }
      };
      if (clv.source === 'segmentation') {
        const selectedSubSeg = [...clv.source_id, event.value.id];
        if (selectedSubSeg.length) {
          getClvTimeframe(
            ApiService.getClvTimeFrame({
              source: 'segmentation',
              ids_list: selectedSubSeg.toString(),
            }),
            resHandler
          );
        }
      } else {
        getClvTimeframe(
          ApiService.getClvTimeFrame({
            ids_list: event.value.id,
            source: 'audience',
          }),
          resHandler
        );
      }
    }
  };

  const convertToDate = (dateString?: string) => {
    //  Convert a "yyyy-mm-dd" string into a Date object
    if (dateString !== undefined && dateString !== "") {
      let d = dateString.split("-");
      let dat = new Date(d[0] + "/" + d[1] + "/" + d[2]);
      return dat;
    } else {
      return undefined;
    }
  };
  const padTo2Digits = (date: number) => {
    return date.toString().padStart(2, "0");
  };

  const formatDate = (date?: Date | null) => {
    return date !== undefined && date !== null
      ? [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/")
      : "";
  };

  const formatDateHyphen = (date?: Date | null) => {
    return date !== undefined && date !== null
      ? [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate())
      ].join("-")
      : "";
  };

  const getAudienceList = useCallback(
    (
      searchText = "",
      createdBy = "",
      page = 0,
      sortBy = "modified_on",
      sortOrder = 1,
      published = "True",
    ) => {
      const resHandler = (res: AudienceListResponse) => {
        setFilteredAudienceList(res.audiences);
      };
      getAudiences(
        ApiService.getAudienceList({
          page: page,
          search: searchText,
          created_by: createdBy,
          sort_by: sortBy,
          sort_order: sortOrder,
          published
        }),
        resHandler
      );
    },
    [getAudiences, clv.is_private]
  );

  const getSegmentationList = useCallback(
    (
      searchText = "",
      createdBy = "",
      published = "True",
      page = 0,
      sortBy = "modified_on",
      sortOrder = 1
    ) => {
      const resHandler = async (res: SegmentationListResponse) => {
        setFilteredSegmentList(res.segmentation);
      };
      getSegmentations(
        ApiService.getSegmentationList({
          page: page + 1,
          search: searchText,
          created_by: createdBy,
          sort_by: sortBy,
          sort_order: sortOrder,
          published,
        }),
        resHandler
      );
    },
    [getSegmentations, clv.is_private]
  );

  const getSubSegmentsList = useCallback(
    (clv_base_id: string) => {
      const resHandler = async (res: SegmentationSummaryResponse) => {
        let arr: SubSegmentDetails[] = [];
        if (res.response.insights.length > 0) {
          arr = res.response.insights.map((item) => ({
            id: item.segment_id.toString(),
            name: item.segment_name.toString(),
          }));
          setAllSubSegmentList(arr);
        }
      };
      getSubSegments(ApiService.getSubSegmentsList(clv_base_id), resHandler);
    },
    [getSubSegments]
  );

  useEffect(() => {
    if (params?.clvid) {
      const resHandler = (res: any) => {
        setClv((oldclv: CLVGeneralDetail) => ({
          ...oldclv,
          name: res.clv.name,
          description: res.clv.description,
          is_private: res.clv.is_private,
          source: res.clv.source,
          can_be_accessed_by: [...res.clv.can_be_accessed_by],
          source_id: [...res.clv.source_id],
          min_date: res?.clv.min_date,
          max_date: res?.clv.max_date,
          clv_predict: res.clv.clv_predict,
        }));

        setSelectedUsers(res?.clv.can_be_accessed_by);
        setTimeframe({ mindate: convertToDate(res.clv.min_date), maxdate: convertToDate(res.clv.max_date) });
        setFromDate(convertToDate(res.clv.min_date));
        setTodate(convertToDate(res.clv.max_date));

        if (res.clv.source === 'segmentation') {
          const segresHandler = (segres: any) => {
            setSelectedSegments(segres?.segmentation.name);
          };
          getSegmentation(
            ApiService.getSegmentationGeneraldetail(res?.segmentation_id),
            segresHandler
          );

          const subsegresHandler = async (summaryres: SegmentationSummaryResponse) => {
            let arr: SubSegmentDetails[] = [];
            if (summaryres.response.insights.length > 0) {
                arr = summaryres.response.insights.map((item) => ({
                id: item.segment_id.toString(),
                name: item.segment_name.toString(),
              }));
              setAllSubSegmentList(arr);
            }
            const selSubSegList = arr.filter(
              (sub: SubSegmentDetails) => res?.clv.source_id.includes(Number(sub.id))
            )
            setSelectedSubSegments(selSubSegList);
          };
          getSubSegments(ApiService.getSubSegmentsList(res?.segmentation_id), subsegresHandler);
        } else {
          const audresHandler = (audres: any) => {
            setSelectedAudience(audres?.audience.name);
          };
          getAudience(
            ApiService.getAudienceGeneraldetail(res?.clv.source_id[0]),
            audresHandler
          );
        }
      };
      getClvGeneralDetails(
        ApiService.getClvGeneralDetails(params?.clvid),
        resHandler
      );
    }
  }, [params?.clvid]);

  useEffect(() => {
    if (params?.aid) {
      const resHandler = (res: any) => {
        setSelectedAudience(res.audience.name);
      };
      getAudience(ApiService.getAudienceGeneraldetail(params?.aid), resHandler);
      setClv({...clv,source_id:[+params.aid]})
    }
  }, [getAudience, params?.aid]);

  useEffect(() => {
    if (params?.sid) {
      const cloneCLv=clv;
      cloneCLv.source='segmentation';
      cloneCLv.clv_base_id=params.sid;
      setClv(cloneCLv);
      const segresHandler = (segres: any) => {
        setSelectedSegments(segres?.segmentation.name);
        getSubSegmentsList(params.sid!);
      };
      getSegmentation(
        ApiService.getSegmentationGeneraldetail(params?.sid),
        segresHandler);
    }
  }, [getSegmentation, params?.sid]);

  

  useEffect(() => {
    if (
      clv.name !== "" &&
      clv.description !== "" &&
      clv.source_id.length !== 0 &&
      clv.min_date !== "" &&
      clv.max_date !== "" 
    ) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
  }, [
    clv.name,
    clv.description,
    clv.source_id,
    clv.min_date,
    clv.max_date,
    clv.clv_predict,
  ]);
  const userSelectionHandler = (event: any) => {
    setSelectedUsers(event.value);
    setClv((oldclv: CLVGeneralDetail) => ({
      ...oldclv,
      can_be_accessed_by: [...event.value],
    }));
  };

  useEffect(() => {
    AuthenticationService.fetchUsers().then((res: any) => {
      let allUsers = res.users.map((usr: any) => ({
        id: usr.name,
        ...usr,
      }));
      setUserList(allUsers);
      setFilteredUsers(allUsers);
    });
  }, []);

  const searchUser = (event: any) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        const resHandler = (res: any) => {
          if (res?.users) {
            let allUsersList = res.users;
            const userListWithoutLoginUser = allUsersList.filter(
              (user: any) => user.id !== currentUserDetails.id
            );
            const finallist = userListWithoutLoginUser.filter(
              (user: any) =>
                !selectedUsers.map((selUsr: any) => selUsr.id).includes(user.id)
            );
            setUserList(finallist);
          }
        };
        getUsers(
          AuthenticationService.getUserSuggestions(event.query.toLowerCase()),
          resHandler
        );
      }
    }, 250);
  };

  const updateCLVDetails = () => {
    const runSummary = (res: any) => {
      const resHandler = (runRes: any) => {
          navigate(
            `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_SUMMARY}/${clvid}`
          );
        }
      runClvModel(ApiService.runClvModel(clvid!), resHandler);
    };
    let clvData = { ...clv };
    if (clvData.is_private) {
      if (clvData.can_be_accessed_by.length) {
        clvData = {
          ...clv,
          can_be_accessed_by: [
            ...clvData.can_be_accessed_by.map((item: any) => item.id),
          ],
        };
      }
    } else {
      clvData = { ...clv, can_be_accessed_by: [] };
    }
    updateCLV(ApiService.updateClvDetails(clvData, clvid), runSummary);
  };

  const createCLVHandler = (event: React.FormEvent, key: 'save' | 'next') => {
    event.preventDefault();
    headerTitleChangeHandler("Create CLV","","");

    const runSummary = (res: any) => {
      const resHandler = (runRes: any) => {
        if (key === 'next') {
          navigate(
            `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_SUMMARY}/${res.id}`
          );
        }
      };
      runClvModel(ApiService.runClvModel(res.id), resHandler);
    };
    if (params?.clvid) {
      updateCLVDetails();
    } else {
      let clvData = { ...clv };
      if (clvData.can_be_accessed_by.length) {
        clvData = {
          ...clv,
          can_be_accessed_by: [
            ...clvData.can_be_accessed_by.map((item: any) => item.id),
          ],
        };
      }
      createCLV(ApiService.createCLVGeneraldetail(clvData), runSummary);
    }
  };
  const searchAudience = (event: { query: string }) => {
    setTimeout(() => {
      getAudienceList(event.query);
    }, 250);
  };

  const searchSegments = (event: { query: string }) => {
    setTimeout(() => {
      getSegmentationList(event.query);
    }, 250);
  };

  const searchSubSegments = (event: { query: string }) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        if (allSubSegmentList.length) {
          const filteredSubSegList = allSubSegmentList
            .filter(
              (sub: SubSegmentDetails) =>
                !selectedSubSegments
                  ?.map((item: SubSegmentDetails) => item.id)
                  .includes(sub.id)
            )
            .filter((sub: SubSegmentDetails) =>
              sub.name.toLowerCase().includes(event.query.trim().toLowerCase())
            );
          setFilteredSubSegmentList(filteredSubSegList);
        }
      }
    }, 250);
  };

  const cancelHandler = () => {
    headerTitleChangeHandler("CLV List",'','');
    navigate(
      `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_LIST}`
    );
  };
  return (
    <Fragment>
      <div className="audiencecreate__steps sticky-top stickytopRecomd">
        <ul className="list-inline">
          <li className="list-inline-item audiencecreate__steps--active">
            General details
          </li>
          <li className="list-inline-item audiencecreate__steps--line steps__active"></li>
          <li className="list-inline-item">Summary</li>
        </ul>
      </div>
      <Loader isLoading={createClvLoader || updateClvLoader || runClvModelLoader}>
        <div className="audiencecreate__form-wrapper recomdgenaraldetailswrapper">
          <div className="audiencecreate__form whiteboxheight">
            <form>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="boxmin-height">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="name"
                            className="form-label mai-form-label"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control mai-input"
                            id="name"
                            name="name"
                            maxLength={200}
                            value={clv.name}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              setClv((oldclv: CLVGeneralDetail) => ({
                                ...oldclv,
                                name: e.target.value,
                              }))
                            }
                            aria-describedby="emailHelp"
                            placeholder="Enter clv name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12"></div>

                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="">
                          <label
                            htmlFor="audienceDesc"
                            className="form-label mai-form-label"
                          >
                            Description
                          </label>
                          <textarea
                            id="audienceDesc"
                            className="form-control mai-textarea"
                            maxLength={500}
                            value={clv.description}
                            onChange={(
                              e: React.ChangeEvent<HTMLTextAreaElement>
                            ) =>
                              setClv((oldclv: CLVGeneralDetail) => ({
                                ...oldclv,
                                description: e.target.value,
                              }))
                            }
                            placeholder="Enter description"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12"></div>

                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="mt-4 mb-3">
                          <div className="form-check form-check-inline ps-0">
                            <label className="form-check-label mai-label-access">
                              Access
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mai-radio-button"
                              type="radio"
                              name="inlineRadioOptions"
                              id="public"
                              value="Public"
                              checked={clv.is_private === false}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setClv({
                                  ...clv,
                                  can_be_accessed_by:[],
                                  is_private: false,
                                });
                                setSelectedUsers([]);
                                }}
                            />
                            <label
                              className="form-check-label mai-radio-label"
                              htmlFor="public"
                            >
                              Public
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mai-radio-button"
                              type="radio"
                              name="inlineRadioOptions"
                              id="private"
                              value="Private"
                              checked={clv.is_private === true}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                setClv((oldclv: CLVGeneralDetail) => ({
                                  ...oldclv,
                                  is_private: true,
                                }))
                              }
                            />
                            <label
                              className="form-check-label mai-radio-label"
                              htmlFor="private"
                            >
                              Private
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12"></div>

                      <div className="col-lg-5 col-md-5 col-sm-12">
                        {clv.is_private && (
                          <div className="mb-3">
                            <label
                              htmlFor="sharewith"
                              className="form-label mai-label-sharewith"
                            >
                              Share with <span> Optional</span>
                            </label>
                            <AutoComplete
                              id="clv_general_user"
                              className="form-control"
                              placeholder={
                                selectedUsers.length === 0
                                  ? "Search users and add"
                                  : ""
                              }
                              value={selectedUsers}
                              suggestions={userList}
                              completeMethod={searchUser}
                              field="name"
                              multiple
                              onChange={userSelectionHandler}
                              aria-label="User"
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12"></div>

                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="mb-0">
                          <label
                            htmlFor="chooseaudience"
                            className="form-label mai-form-label choosedata-label"
                          >
                            <span>
                              Choose Data
                              {/* <img
                            src={mai_question_icon}
                            alt="choose audience"
                            className="img-fluid ms-1"
                          /> */}
                            </span>
                            <span>
                              {" "}
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input mai-radio-button"
                                  type="radio"
                                  name="inlineRadioOptionsAud"
                                  id="audience"
                                  value="audience"
                                  checked={clv.source === 'audience'}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setClv((oldClv: CLVGeneralDetail) => ({
                                      ...oldClv,
                                      source: 'audience',
                                      clv_base_id: "",
                                      source_id: [],
                                    }));
                                    setSelectedSegments("");
                                    setSelectedSubSegments([]);
                                  }}
                                />
                                <label
                                  className="form-check-label mai-radio-label"
                                  htmlFor="audience"
                                >
                                  Audience
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input mai-radio-button"
                                  type="radio"
                                  name="inlineRadioOptionsAud"
                                  id="segment"
                                  value="segment"
                                  checked={clv.source === 'segmentation'}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setClv((oldclv: CLVGeneralDetail) => ({
                                      ...oldclv,
                                      source: 'segmentation',
                                      source_id: [],
                                      clv_base_id: ""
                                    }));
                                    setSelectedAudience("");
                                  }}
                                />
                                <label
                                  className="form-check-label mai-radio-label"
                                  htmlFor="segment"
                                >
                                  Segment
                                </label>
                              </div>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12 d-flex align-items-center justify-content-start ps-0"></div>

                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="mb-0">
                          {clv.source === 'audience' && (
                            <AutoComplete
                              className="form-control chooseaudience_input mai-input"
                              placeholder="Search for Audience"
                              value={selectedAudience}
                              suggestions={filteredAudienceList}
                              completeMethod={searchAudience}
                              field="name"
                              onChange={(e: any) => {
                                setClv((oldclv: CLVGeneralDetail) => ({
                                  ...oldclv,
                                  source_id: [],
                                }));
                                if (e.value.id !== undefined) {
                                  setClv((oldclv: CLVGeneralDetail) => ({
                                    ...oldclv,
                                    source_id: [e.value.id],
                                  }));
                                  setSelectedAudience(e.value.name);
                                } else {
                                  setClv((oldclv: CLVGeneralDetail) => ({
                                    ...oldclv,
                                    source_id: [],
                                  }));
                                  setSelectedAudience(e.value);
                                }
                              }}
                              onSelect={fetchTimeframe}
                              aria-label="chooseaudience"
                            />
                          )}
                          {clv.source === 'segmentation' && (
                            <AutoComplete
                              className="form-control chooseaudience_input mai-input"
                              placeholder="Search for Segments"
                              value={selectedSegments}
                              suggestions={filteredSegmentList}
                              completeMethod={searchSegments}
                              field="name"
                              onChange={(e: any) => {
                                setClv((oldclv: CLVGeneralDetail) => ({
                                  ...oldclv,
                                  clv_base_id: '',
                                  source_id: [],
                                }));
                                setSelectedSubSegments([]);
                                if (e.value.id !== undefined) {
                                  setClv((oldclv: CLVGeneralDetail) => ({
                                    ...oldclv,
                                    clv_base_id: e.value.id,
                                  }));
                                  setSelectedSegments(e.value.name);
                                } else {
                                  setClv((oldclv: CLVGeneralDetail) => ({
                                    ...oldclv,
                                    clv_base_id: '',
                                    source_id: [],
                                  }));
                                  setSelectedSegments(e.value);
                                }
                              }}
                              onSelect={(e: any) => {
                                getSubSegmentsList(e.value.id);
                              }}
                              aria-label="chooseaudience"
                            />
                          )}

                          {clv.source === 'segmentation' && (
                            <AutoComplete
                              className="form-control chooseaudience_input subsegment-field"
                              placeholder="Search for Sub Segments"
                              value={selectedSubSegments}
                              suggestions={filteredSubSegmentList}
                              completeMethod={searchSubSegments}
                              field="name"
                              multiple
                              onChange={(e: any) => {
                                const selSeg =
                                  e.value?.map((item: any) =>
                                    Number(item.id)
                                  ) ?? [];
                                setClv((oldclv: CLVGeneralDetail) => ({
                                  ...oldclv,
                                  source_id: selSeg,
                                }));
                                setSelectedSubSegments(e.value);
                              }}
                              onSelect={fetchTimeframe}
                              aria-label="chooseaudience"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                        <div className="createcampaign-or">
                          <span> OR</span>
                        </div>
                        <div className="campaignbutton">
                          <NavLink
                            to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_GENERAL}`}
                            className="createbutton"
                          >
                            <img
                              src={mai_plus_solid_blue}
                              alt="plus"
                              className="img-fluid"
                            />{" "}
                            Create Audience
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label
                          className="form-label mai-form-label">
                          Choose timeframe
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label htmlFor="fromdate" className="form-label mai-form-label">
                          From
                        </label>
                        <div className="defineCalendar d-block">
                          <Calendar
                            panelClassName="range-calendar-pannel"
                            className={""}
                            minDate={timeframe.mindate}
                            maxDate={timeframe.maxdate}
                            id="fromdate"
                            value={fromdate}
                            onChange={(e: any) => {
                              setFromDate(e.value);
                              setClv((oldclv: CLVGeneralDetail) => ({
                                ...oldclv,
                                min_date: formatDateHyphen(e.value),
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label htmlFor="todate" className="form-label mai-form-label">
                          To
                        </label>
                        <div className="defineCalendar d-block">
                          <Calendar
                            panelClassName="range-calendar-pannel"
                            className={""}
                            minDate={timeframe.mindate}
                            maxDate={timeframe.maxdate}
                            id="todate"
                            value={todate}
                            onChange={(e: any) => {
                              setTodate(e.value);
                              setClv((oldclv: CLVGeneralDetail) => ({
                                ...oldclv,
                                max_date: formatDateHyphen(e.value),
                              }));
                            }}
                          />
                        </div>
                      </div>
                  </div>
                    <div className="row mt-4">
                      <div className="col-lg-4 col-md-4 col-sm-12">

                        <div
                          className="form-label mai-form-label">
                          CLV Prediction
                        </div>
                        <div className="form-check form-check-inline ps-3">
                          <input
                            className="form-check-input mai-radio-button"
                            type="radio"
                            name="clvprediction"
                            id="months3"
                            value="3"
                            checked={clv.clv_predict === 3}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setClv((oldclv: CLVGeneralDetail) => ({
                          ...oldclv,
                          clv_predict: 3,
                        }));
                            }}
                          />
                          <label
                            className="form-check-label mai-radio-label"
                            htmlFor="months3"
                          >
                            3 Months
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input mai-radio-button"
                            type="radio"
                            name="clvprediction"
                            id="months6"
                            value="6"
                            checked={clv.clv_predict === 6}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setClv((oldclv: CLVGeneralDetail) => ({
                          ...oldclv,
                          clv_predict: 6,
                        }));
                            }}
                          />
                          <label
                            className="form-check-label mai-radio-label"
                            htmlFor="months6"
                          >
                            6 Months
                          </label>
                        </div>
                        <div className="form-check form-check-inline me-0">
                          <input
                            className="form-check-input mai-radio-button"
                            type="radio"
                            name="clvprediction"
                            id="months9"
                            value="9"
                            checked={clv.clv_predict === 12}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setClv((oldclv: CLVGeneralDetail) => ({
                          ...oldclv,
                          clv_predict: 12,
                        }));
                            }}
                          />
                          <label
                            className="form-check-label mai-radio-label"
                            htmlFor="months9"
                          >
                            9 Months
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="rules__border-top">
                    <div className="row mt-3">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <button
                          className="btn btn-primary mai-cancel-button"
                          onClick={cancelHandler}
                        >
                          Cancel
                        </button>

                        <button
                          className="btn btn-primary mai-cancel-button ms-3"
                          onClick={(e: any) => createCLVHandler(e, 'save')}>
                          Save as draft
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                        <button
                          type="submit"
                          className="btn btn-primary mai-next-button"
                          disabled={!enableNext}
                          onClick={(e: any) => createCLVHandler(e, 'next')}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Loader>
    </Fragment>
  );
};

export default ClvGeneralDetailsComponents;
