import React, { Fragment, useCallback, useState } from 'react';
import { DataTable as TableView, DataTableSelectionCellChangeEvent, DataTableCellSelection } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UtilService from '../../../services/util.service';
import { Columns } from '../../../models/types';

type MyProps = { data: [], columns: Columns[], loading?: boolean, config?: any }

const DataTable = (props: MyProps) => {
    const capitalizeTheFirstLetterOfEachWord = (words: string): any => {
        return UtilService.capitalizeTheFirstLetterOfEachWord(words);
    }
    const removeUnderscoreAsSpace = (str: string): any => {
        return UtilService.removeUnderscoreAsSpace(str);
    }
    const getNumberasCommasepareted = (num: any): any => {
        return UtilService.getNumberasCommasepareted(num);
    }
    const onSelectionHandler = (event: any) => {
        if(props?.config?.selection) {
            props?.config?.selection?.handler(event.value);
        }
    }
    const dynamicColumns = props.columns.map((col: any, index: any) => {
        return <Column
            key={index}
            field={col.field && col.field}
            header={ col.header !== undefined && removeUnderscoreAsSpace(col.header)}
           // style={{width: col?.width ? `${col?.width}` : `${100/(props.columns.length)}%`}}
            sortable={col.sort ? col.sort : false} 
            body={col?.body ? col?.body : ''}
            selectionMode={col.selectionMode && col.selectionMode}
            headerStyle={col.headerStyle && col.headerStyle}
            alignHeader={col?.align ? col?.align : 'center'}
            align={col?.align ? col?.align : 'center'}
            sortFunction={col?.sortFunction ? col?.sortFunction : undefined}
            />;
    });
    return (
        <Fragment>
            <TableView
                className='ut_table'
                sortMode={props?.config?.sortmode ? props?.config?.sortmode : 'single'}
                sortOrder={props?.config?.sortorder ? props?.config?.sortorder : 1}
                sortField={props?.config?.sortfield ? props?.config?.sortfield : undefined}
                onSort={props?.config?.onsort ? props?.config?.onsort : undefined}
                value={props.data}
                dataKey={props?.config?.dataKey ?? 'id'} 
                metaKeySelection={props?.config?.selection?.metakey} 
                loading={props.loading ? props.loading : false}
                scrollable
                scrollHeight={props?.config?.scrollheight ? props?.config?.scrollheight : '400px'}
                selectionMode={props?.config?.selection?.mode ? props?.config?.selection.mode : 'single'} 
                selection={props?.config?.selection?.selected} 
                onSelectionChange={onSelectionHandler}
                cellSelection={props?.config?.cellSelection ?? true} 
                isDataSelectable={props?.config?.isDataSelectable}
                // reorderableColumns
                resizableColumns 
                columnResizeMode="fit">
                {dynamicColumns}
            </TableView>
        </Fragment>
    );
}

export default React.memo(DataTable);