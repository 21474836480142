import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../../../shared/components/datatable/DataTable'
import { Column } from 'primereact/column'
import { NavLink, useParams } from 'react-router-dom'
import { mai_cagrminus, mai_cagrplus, mai_export_icon, mai_filter_wbg_icon, mai_save_audience_icon, mai_search_bar } from '../../../../../../assets/images'
import { Paginator } from 'primereact/paginator'
import Loader from '../../../../../../shared/components/loader/Loader'
import useHttp from '../../../../../../hooks/use-http'
import ApiService from '../../../../../../services/api.service'
import UtilService from '../../../../../../services/util.service'
import { Columns } from '../../../../../../models/types'

const CustomersComponent = () => {
    const params = useParams();
    const [tableData, setTableData] = useState<any>([]);
    const [first, setFirst] = useState(1);
    const [rows, setRows] = useState(10);
    const [tableDataCount, setTableDataCount] = useState<any>(0);
    const [searchKey, setSearchKey] = useState<string>("");
    const [productColorSet, setProductColorSet] = useState<any>([]);
    const { sendRequest: fetchCustomersList, loading: fetchCustomersListLoader } = useHttp();

    const getCustomersList = useCallback((search= '', page= 1) => {
        const data = {
            customers: 10,
            products: 3,
            page: page,
            searchText: search
        };
        const resHandler = (res: any) => {
            console.log("customer List", res);
            let productSet: any = [];
            res.top_customers.forEach((item: any) => productSet.push(...item.products));
            const newSet: any = new Set(productSet);
            setProductColorSet([...newSet]);
            const dataSet = res.top_customers.map((cust: any) => {
                const change_perc = cust.current_acv > 0 && cust.previous_acv > 0 ? (cust.change / cust.previous_acv) * 100 : 0;
                return {
                    ...cust,
                    change_perc
                }
            });
            setTableData(dataSet);
            setTableDataCount(res.total);
        }
        fetchCustomersList(ApiService.clvInsightsTopCustomers(params.clvid!, data), resHandler);
    }, [fetchCustomersList, params.clvid]);
    useEffect(() => {
        getCustomersList();
    }, [getCustomersList]);
    const customerNameTemplate = (rowData: any) => {
        return <>{UtilService.capitalizeTheFirstLetterOfEachWord(rowData.customer_name)}</>
    }

    const survivalProbabilityBodyTemplate = (rowData: any) => {
        // return <div className={`${rowData.survival_probability > 0 ? 'raise' : 'fall'}`}>{`${rowData.survival_probability > 0 ? '+' : ''}`}{Math.round(rowData.survival_probability.toFixed(2))}%</div>
        return <>{Math.round(rowData.survival_probability.toFixed(2))}%</>
    }

    const forecastRevenueBodyTemplate = (rowData: any) => {
        let classname: string = "text-success";
        let forecast_revenue = String(Math.round(rowData.forecast_revenue));
        if (forecast_revenue.indexOf('-') > -1) {
            classname = "text-danger";
        }
        return (
            <>
                <span>${UtilService.numberFormatter(Number(rowData.forecast_revenue))}</span>
                <img src={classname === "text-danger" ? mai_cagrminus : mai_cagrplus} alt="CAGR" />
            </>
        );
    }

    const avgCLVBodyTemplate = (rowData: any) => {
        return <>${UtilService.numberFormatter(Number(rowData.avg_clv))}</>
    }

    const topproductsBodyTemplate = (rowData: any) => {
        return <>
            {rowData.products.map((product: string, index: number) =>
                <span key={`cust_prod_${index}`} className={`product-badge`} style={{ 'background': `${productColorCode(product)}` }}>
                    {UtilService.capitalizeTheFirstLetterOfEachWord(product)}
                </span>
            )}
        </>
    }
    const productColorCode = (product: any) => {
        return UtilService.returnColorSchema()[productColorSet.indexOf(product)];
    }
    const columns: Columns[] = [
        {
            field: "customer_name",
            header: "Customer Name",
            sort: true,
            body: customerNameTemplate,
        },
        {
            field: "survival_probability",
            header: `Survival Probability (%)`,
            body: survivalProbabilityBodyTemplate,
            sort: true,
            align: "center",
        },
        {
            field: "avg_clv",
            header: "Avg. CLV ($)",
            body: avgCLVBodyTemplate,
            sort: true,
            align: "center",
        },
        {
            field: "forecast_revenue",
            header: `Forcast Revenue ($)`,
            body: forecastRevenueBodyTemplate,
            sort: true,
            align: "center",
        },
        {
            field: "products",
            header: "Top 3 Products",
            body: topproductsBodyTemplate,
            sort: false,
            align: "center"
        },
    ];
    const pageChangeHandler = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        getCustomersList(searchKey, event.page);
      };
      
  const customerTextSearchHandler = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setSearchKey(event.currentTarget.value);
    getCustomersList(event.currentTarget.value);
  };
  return (
    <>
        <div className="audiencecreate__form audiencelist boxshadow whiteboxheight-clvdetails-customer">
                                <div className="row mb-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className="rules__searchbox">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend border-0">
                                                            <button
                                                                id="button-addon4"
                                                                type="button"
                                                                className="btn btn-link text-info"
                                                            >
                                                                <img
                                                                    src={mai_search_bar}
                                                                    alt="search"
                                                                    className="img-fluid"
                                                                />
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="search"
                                                            placeholder="Search"
                                                            aria-describedby="button-addon4"
                                                            className="form-control border-0"
                                                            onChange={customerTextSearchHandler}
                                                            value={searchKey}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="audiencelist__select">
                    <label htmlFor="createdby">Created by</label>
                    <select
                      className="form-select"
                      id="createdby"
                      defaultValue={"All"}
                      value={creatorKey}
                      onChange={creatorChangeHandler}
                    >
                      <option value={''}>All</option>
                      {!getCreatorsLoader &&
                        creatorList.map((item: { id: string, name: string }) => <option key={item.id} value={item.id}>{item.name}</option>)
                      }
                    </select>
                  </div>
                </div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="heading-button d-flex justify-content-end segment-button">
                                            <NavLink to="#" data-bs-toggle="modal" data-bs-target="#filterpopup"><img src={mai_filter_wbg_icon} alt="filter" className="img-fluid" /> Filters</NavLink>
                                            <NavLink to="#" data-bs-toggle="modal" data-bs-target="#saveasaudience"><img src={mai_save_audience_icon} alt="save audience" className="img-fluid" /> Save as audience</NavLink>
                                            <NavLink to="#" data-bs-toggle="modal" data-bs-target="#customerprofile"><img src={mai_export_icon} alt="export" className="img-fluid" /> Export</NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="card list-datatable audiencelist-datatable audiencedetails-table top20customerwrap border-0">
                                    {fetchCustomersListLoader ?
                                        <Loader style={{minHeight: "407px"}}></Loader>
                                        : <>
                                        <div className="customers-datatable">
                                            <DataTable
                                            data={tableData}
                                            columns={columns}
                                            ></DataTable>
                                        </div>
                                        </>
                                    }
                                    <Paginator
                                        first={first}
                                        rows={rows}
                                        totalRecords={tableDataCount}
                                        onPageChange={pageChangeHandler}
                                        className="justify-content-end listpagination"
                                    ></Paginator>
                                    </div>
                                </div>


                            </div>
    </>
  )
}

export default CustomersComponent